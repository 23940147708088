.container {
  // max-width: 1715px;
  height: 89vh;
  max-width: 100vw;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  // background-color: aqua;
}

.game {
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  gap: clamp(1px, 0.1vw, 2px);
}


.table-tales {
  display: flex;
  flex-direction: column;
  gap: clamp(1px, 0.1vw, 2px);

  &--big {
    gap: clamp(5px, 1.3vw, 30px);

    & > .row {
      gap: clamp(5px, 1.3vw, 30px);

    }
  }

  &--small {
    position: relative;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
    }
  }

  &--draw {
    .tale {
      opacity: .5;
    }
  }
}

.tale {
  width: clamp(20px, 7vw, 76px);
  height: clamp(20px, 7vw, 76px);

  padding: 0;
  margin: 0;
  border: 0;

  background: none;
  background-image: url('./images/kletka.svg');
  background-size: contain;

  transition: .25s;

  // border-radius: 18px;
  // background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #EAEAEA;
  // border: 1px solid rgba(0, 0, 0, 0.05);
  // box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.1);
  // filter: blur(0.5px);
  @media (100vh < width){
    width: clamp(20px, 9vh, 76px);
    height: clamp(20px, 9vh, 76px);
  }

  &--with-img {
    margin: 0;
    padding: 0;
    border: 0 !important;
    animation: 0;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &:nth-child(even) {
    animation-name: rot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
  }

  &:nth-child(odd) {
    animation-name: rev-rot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
  }

  &--with-img:nth-child(odd){
    animation: none;
  }

  &--with-img:nth-child(even){
    animation: none;
  }

  &:hover{
      cursor: pointer;
  }

  &:disabled {
    animation-name: none;
    opacity: .6;
    // background: #EAEAEA;
    // border: 1px solid rgba(0, 0, 0, 0.05);
    // box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.1);
    // filter: blur(0.5);
  }
  &:active{
    border-style: outset;
    --webkit-appearance: none !important;
    // background-image: url('./images/nolik_anim.gif');
  }

  &--with-img:disabled{
    opacity: 1;
  }
}

.main {
  margin: auto;

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__button {
    width: 85%;
    height: 10%;
    margin: auto;

    padding: 0;
    display: flex;
    border: none;
    background: none;
    align-items: center;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__left{
    width: clamp(45px, 15vw, 150px); //тут vh надо
    display: flex;
    justify-content: center;

    background-image: url("./images/bok.svg");
    transform: scaleX(-1);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @media (100vh < width){
      width: clamp(45px, 15vh, 150px);
    }
  }

  &__right{
    width: clamp(45px, 15vw, 150px); //тут vh надо
    display: flex;
    justify-content: center;

    background-image: url("./images/bok.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @media (100vh < width){
      width: clamp(45px, 15vh, 150px);
    }
  }
}

.eye{
  background-image: url("./images/eye.svg");
}

.eye-hide{
  background-image: url("./images/eye-hide.svg");
}

.reset{
  background-image: url("./images/reset.svg");
}

.how-play {
  width: 90%;
  height: 86%;

  position: fixed;
  top: 7%;
  left: 5%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  box-shadow: inset 0px 5px 40px 0px rgba(46, 36, 25, 0.05),inset 0px -5px 20px 0px rgba(52, 41, 29, 0.05),inset 100px 0px 100px 0px rgba(48, 29, 29, 0.05),inset -100px 0px 100px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(15px);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5vw;
  
  mask: url("./images/maska-for-modal.svg") top/55% no-repeat, linear-gradient(#000 0 0); /* linear-gradient(#000 0 0) need this extra layer for mask-composite */
  mask-composite: exclude;

  z-index: 2;
  
  &__gifka{
    width: auto;
    height: min(80%, 83vw);
    aspect-ratio: 1 / 1;
    background-color: antiquewhite;
    margin: 7% 0 0 4%;
    border-radius: 3vw;
  }

  &--opened {
    display: flex;
  }

  &__images {
    padding: 0 60px;
    display: flex;
    flex-wrap: wrap;
    gap: 75px;
  }

  &__description{
    // width: max(41%, 75vw);
    width: 75vw;

    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (100vh < width){
      width: 41%;
    }
  }

  &__textik{
    color: rgb(0, 0, 0);
    font-family: 'Kretex_thin';
    font-size: clamp(30px, 10vw, 95px);
    font-weight: 400;
    line-height: clamp(30px, 5vw, 95px);
    letter-spacing: 0px;
    text-align: left;

    transition: 1s;
    @media (100vh < width){
      font-size: clamp(30px, 5vw, 95px);
      line-height: clamp(30px, 3.75vw, 95px);
    }
  }

  &__next {
    width: 145px;
    height: 40px;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    // margin-top: 245px;
    margin-top: 25px;

    &--hide {
      display: none;
    }
  }

  &__close {
    cursor: pointer;
    border: 0;
    background-color: transparent;
    width: 350px;
    height: 62px;
    display: flex;
    margin: 0 auto 32px;
  }

}

// .how-play::after{
//   content: "";
//   width: 55%;
//   height: 20%;

//   position: absolute;
//   top: 95%;
//   left: 22.5%;
//   // margin: 0 auto;
//   background-image: url('./images/lol.svg');
//   background-position: top;
//   background-size: contain;
//   background-repeat: no-repeat;
//   // mask-image: url("./images/lol.svg");
//   // mask-size: contain;

// }

.finalInscription{
  width: auto;
  height: auto;
  position: absolute;
  padding: 0 3vw;
  top: clamp(min(300px, 36.5vh), 17vw, 310px);
  margin: 0;

  border-radius: 2vw;
  box-shadow: inset 0px 5px 40px 0px rgba(192, 192, 192, 0.25),inset 0px -5px 20px 0px rgba(207, 207, 207, 0.25),inset 100px 0px 100px 0px rgba(204, 204, 204, 0.25),inset -100px 0px 100px 0px rgba(175, 175, 175, 0.05);
  backdrop-filter: blur(35px);
  background: rgba(0, 0, 0, 0.06);

  color: rgb(0, 0, 0);
  font-family: 'Kretex_thin';
  font-size: clamp(30px, 14vh, 125px);
  font-weight: 400;

  z-index: 1;
  @media (100vh > width){
      top: 40%;
      padding: 0 4vw;
      font-size: clamp(30px, 11vw, 125px);
  }
}

.how-play-open {
  width: 100%;
  height: 9vh;
  max-height: 120px;
  // max-width: 360px;

  justify-content: center;

  display: flex;
  cursor: pointer;
  border: none;
  background: #fff;
  margin: 0 auto 0;

  img {
    padding: 0;
    margin: 0;

    width: clamp(100px, 35vw, 360px);
    max-width: 360px;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

@keyframes rot {
  0% {
    transform: matrix(0, 1, 1, 0, 0, 0);
  }
  100% {
    transform: matrix(0.2, 1, 1, -0.2, 0, 0);
    // transform: matrix(0, 1, 1, 0, 0, 0);
  }
  // 100% {
  //   transform: matrix(-0.1, 1, 1, 0.1, 0, 0);
  // }
}


@keyframes rev-rot {
  0% {
    transform: rotate(0);
  }
  // 50% {
  //   transform: rotate(5deg);
  //   // transform: matrix(0, 1, 1, 0, 0, 0);
  // }
  100% {
    transform:rotate(5deg);
  }
}
